import { Site } from 'services/company/companyModels';

export type NAICSSectorType = 'private' | 'state' | 'local';

export const naicsSectorTypeLookup = {
  'private': 'Private',
  'state': 'State Government',
  'local': 'Local Government'
};

export class InjuryRateTable {
  private _rows: InjuryRateRow[] = [];
  public startYear: number;
  public blsRate: number;
  public blsTCIR: number;
  public blsDART: number;
  public averageTCIR: number;
  public averageDART: number;
  public percentDiffTCIR: number;
  public percentDiffDART: number;
  public totalHours: number;
  public totalInjuries: number;
  public totalDaysAwayFromWork: number;
  public naicsCode: string;
  public naicsSectorType: NAICSSectorType;
  public historicalRates?: HistoricalNAICSRates;
  public isContractor: boolean;

  constructor(table: InjuryRateTable) {
    if (!table) {
      return;
    }

    this.blsRate = table.blsRate;
    this.blsTCIR = table.blsTCIR;
    this.blsDART = table.blsDART;
    this.averageTCIR = table.averageTCIR;
    this.averageDART = table.averageDART;
    this.percentDiffTCIR = table.percentDiffTCIR;
    this.percentDiffDART = table.percentDiffDART;
    this.naicsCode = table.naicsCode;
    this.naicsSectorType = table.naicsSectorType;
    this.historicalRates = table.historicalRates;
    this.totalHours = table.totalHours || 0;
    this.totalInjuries = table.totalInjuries || 0;
    this.totalDaysAwayFromWork = table.totalDaysAwayFromWork || 0;
    this.isContractor = !!table.isContractor;

    if (table.rows.length) {
      // Convert from plain JSON
      this._rows = table.rows.map((row: any) => Object.assign(new InjuryRateRow, row));
    }

    if (table.startYear) {
      this.startYear = table.startYear;
    } else if (this._rows && !this.isContractor) {
      // Backfill extended year
      this.startYear = this._rows[this._rows.length - 1].year;
      const firstYear = this._rows[0].year;
      this._rows.unshift(new InjuryRateRow(firstYear - 1));
    }
  }

  toJSON(): unknown {
    return {
      blsRate: this.blsRate,
      blsTCIR: this.blsTCIR,
      blsDART: this.blsDART,
      averageTCIR: this.averageTCIR,
      averageDART: this.averageDART,
      percentDiffTCIR: this.percentDiffTCIR,
      percentDiffDART: this.percentDiffDART,
      naicsCode: this.naicsCode,
      naicsSectorType: this.naicsSectorType,
      historicalRates: this.historicalRates,
      startYear: this.startYear,
      rows: this._rows,
    };
  }

  public get rows(): InjuryRateRow[] {
    return this._rows.slice(this.isContractor || this.isEligibleForSmallWorksiteCalculation() ? 0 : 1);
  }

  public configure(year: number, options: InjuryRateTableOptions, includedRowsOnReset: InjuryRateRow[] = []): InjuryRateTable {
    if (!this.rows.length || includedRowsOnReset.length) {
      // For participants only, add an additional row that can be used
      // for small worksite calculation
      this._rows = [];
      this.startYear = year;
      const earliestYear = year - options.numberOfYears + (this.isContractor ? 1 : 0);
      for (let currYear = earliestYear; currYear <= this.startYear; currYear++) {
        const existingRowForYear = includedRowsOnReset.find(row => row.year === currYear); 
        this._rows.push(existingRowForYear ? existingRowForYear : new InjuryRateRow(currYear));
      }
    }
    return this;
  }

  public recalculateAverages(options: InjuryRateTableOptions, blsCodesValid?: boolean): void {
    const hasValidBLSRates = blsCodesValid ?? this.hasValidBlsRates(options);
    const rows = this.isEligibleForSmallWorksiteCalculation()
      ? this.findBestRows()
      : this.rows;
    this.averageTCIR = this.average(rows.map(row => ({ pop: row.workHours, n: row.injuries })));
    this.averageDART = this.average(rows.map(row => ({ pop: row.workHours, n: row.daysAwayFromWork })));
    this.percentDiffTCIR = hasValidBLSRates ? (this.averageTCIR - this.blsTCIR) / this.blsTCIR * 100 : undefined;
    this.percentDiffDART = hasValidBLSRates ? (this.averageDART - this.blsDART) / this.blsDART * 100 : undefined;

    this.totalHours = rows.reduce((count, row) => count + (+row.workHours), 0);
    this.totalInjuries = rows.reduce((count, row) => count + (+row.injuries), 0);
    this.totalDaysAwayFromWork = rows.reduce((count, row) => count + (+row.daysAwayFromWork), 0);
  }
  
  public hasValidBlsRates(options: InjuryRateTableOptions): boolean {
    if (!this.historicalRates) {
      return false;
    }
    const availableBlsYears = Object.keys(this.historicalRates).sort();
    const requiredBlsYears = options.numberOfYears;
    let haveValidBLSRates = true;

    for (let i = 1; i <= requiredBlsYears; i++) {
      const requiredBLSYear = this.startYear - i;
      if (!availableBlsYears.includes(requiredBLSYear.toString())) {
        haveValidBLSRates = false;
      }
    }

    return haveValidBLSRates;
  }

  private findBestRows() {
    const { _rows: rows } = this;
    const allRowsFilledOut = rows.every(row => !!row.workHours && !!row.injuries);

    if (!allRowsFilledOut) {
      return rows;
    }

    return rows.slice().sort((a, b) =>
      (b.injuries as number) / (b.workHours as number) -
      (a.injuries as number) / (a.workHours as number)
    ).slice(1);
  }

  private sum(series: number[]): number {
    return series.reduce((p, c) => p + (+c || 0), 0);
  }

  private average(series: { pop: number | ''; n: number | '' }[]): number {
    const seriesWithValues = series.filter(item =>
      item.pop !== null && item.pop !== '' && isFinite(item.pop) &&
      item.n !== null && item.n !== '' && isFinite(item.n)
    );
    return this.sum(seriesWithValues.map(item => item.n as number)) / this.sum(seriesWithValues.map(item => item.pop as number)) * 200000;
  }

  public mostRecentYear(): InjuryRateRow {
    return this._rows[this._rows.length - 1];
  }

  public isEligibleForSmallWorksiteCalculation(): boolean {
    const mostRecentYear = this.mostRecentYear();
    if (!mostRecentYear?.workHours || !this.historicalRates || this.isContractor) {
      return false;
    }

    // Assuming 2 incidents in 200,000 hours
    const hypotheticalTCIR = 400000 / mostRecentYear.workHours;
    const years = this._rows.slice(-3);
    const historicalTCIRForIndustry = years.map(year => this.historicalRates[year.year]?.tcirRate).filter(rate => typeof rate === 'number');

    return historicalTCIRForIndustry.some(rate => rate < hypotheticalTCIR);
  }

  public describeDataUsed(): string {
    return this.isEligibleForSmallWorksiteCalculation() ? `Rate over best ${this.rows.length - 1} years` : `${this.rows.length} year rate`;
  }

  private readonly fixedSiteColumns: Record<string, string> = {
    'employees': 'Number of Employees',
    'workHours': 'Hours Worked',
    'tcir': 'TCIR',
    'dart': 'DART Rate',
  };

  private readonly mobileOrConstructionSiteColumns: Record<string, string> = {
    'employees': 'Total Number of All Site/DGA Employees Including All Contractor Employees',
    'workHours': 'Hours Worked of All Site/DGA Employees Including All Contractor Employees',
    'tcir': 'Combined TCIR',
    'dart': 'Combined DART Rate',
  };

  public getLabel(column: string, site: Site): string {
    const lookup = site.siteType === 'non-construction'
      ? this.fixedSiteColumns
      : this.mobileOrConstructionSiteColumns;

    return lookup[column];
  }
}

export class InjuryRateRow {
  year: number;
  employees: number | '' = 0;
  workHours: number | '' = 0;
  injuries: number | '' = 0;
  daysAwayFromWork: number | '' = 0;
  tcir: number;
  dart: number;
  dartIsValid = true;

  public constructor(year?: number) {
    if (year) {
      this.year = year;
    }
  }

  public recalculate(): void {
    this.tcir = rate(this, this.injuries);
    this.dart = rate(this, this.daysAwayFromWork);
    this.dartIsValid = Number(this.daysAwayFromWork) <= Number(this.injuries);
  }
}

export class InjuryRateTableOptions {
  numberOfYears: number;
}

export class ContractorInjuryRateTable {
  rows: ContractorInjuryRateTableRow[] = [];

  constructor(table: unknown) {
    Object.assign(this, table);
    if (this.rows.length) {
      // Convert from plain JSON
      this.rows = this.rows.map(row => Object.assign(new ContractorInjuryRateTableRow(row)));
    }
  }

  public totals(): InjuryRateRow {
    if (!this.rows?.length) {
      return null;
    }

    const totals = this.rows.reduce((result, table) => {
      const row = table.table.mostRecentYear();
      (result.employees as number) += +row.employees;
      (result.workHours as number) += +row.workHours;
      (result.injuries as number) += +row.injuries;
      (result.daysAwayFromWork as number) += +row.daysAwayFromWork;
      return result;
    }, new InjuryRateRow());

    totals.recalculate();
    return totals;
  }
}

export class ContractorInjuryRateTableRow {
  contractorName: string;
  table: InjuryRateTable = new InjuryRateTable(null);

  constructor(row?: ContractorInjuryRateTableRow) {
    if (row?.table) {
      row.table.isContractor = true;
      this.table = new InjuryRateTable(row.table);
    } else {
      this.table.isContractor = true;
    }
    this.contractorName = row?.contractorName;
  }

  public configure(year: number, options: InjuryRateTableOptions): ContractorInjuryRateTableRow {
    this.table.configure(year, options);
    return this;
  }
}

function rate(row: InjuryRateRow, n: string | number): number {
  const eh = row.workHours;
  if (n === '' || eh as any === '') {
    return NaN;
  }
  return (+n) / (eh || 0) * 200000;
}

/**
 * 
*/
export type HistoricalNAICSRates = {
  [year: string]: HistoricalNAICSRate;
};

/**
 * HistoricalNAICSRate is a alias for a single key object that holds
 * a NAICS code as the key and it's associated TCR Rate as the value.
 * {"112": 4.5}
 */
export type HistoricalNAICSRate = {
  naicsCode: string;
  tcirRate: number;
  dartRate: number;
};

export type InjuryRate = {
  industry: string;
  naics: string;
  tcir: string;
  dart: string;
  state: string;
};

export type NAICSIndustry = { [naicsCode: string]: InjuryRate };

export type NAICSSector = {
  private: NAICSIndustry;
  state: NAICSIndustry;
  local: NAICSIndustry;
};

export type NAICSRegion = { [state: string]: NAICSSector };

export type InjuryRateRepo = { [year: string]: NAICSSector };

export type InjuryRateDatabase = { [year: string]: NAICSRegion };
