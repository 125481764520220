import React from 'react';
import { HistoricalNAICSRate, InjuryRateRow, InjuryRateTable, InjuryRateTableOptions, NAICSSectorType, naicsSectorTypeLookup } from '../../../services/audit/injuryRateModels';
import { AssessmentLock } from './assessmentLock';
import getBLSRepo, { BLSRepo } from './blsData';
import { Lock } from './editLock';

export default class ParticipantInjuryRateTableEditor extends React.Component<ParticipantInjuryRateTableEditorProps, ParticipantInjuryRateTableEditorState> {
  private table: InjuryRateTable;
  private blsRepo: BLSRepo;

  constructor(props: ParticipantInjuryRateTableEditorProps) {
    super(props);
    this.table = new InjuryRateTable(props.value);
    this.state = {
      naicsCodeSwapped: false,
      rateState: '',
      searchedCode: '',
      naicsSectorType: props.value.naicsSectorType || 'private'
    };
    getBLSRepo(this.props.auditId).then(repo => {
      this.blsRepo = repo;
      this.forceUpdate();
      if (props.value.naicsCode) {
        this.setNAICS(props.value.naicsCode, props.value.naicsSectorType);
      }
    });
  }

  public render(): JSX.Element {
    if (!this.blsRepo) {
      return <React.Fragment />;
    }

    const data = this.table;
    const validRates = this.blsRepo.haveValidBLSRates(this.table.startYear);
    const haveMinimumYear = this.table.startYear >= this.blsRepo.minimumAssessmentYear();
    const editLock = this.props.lock?.lockType === 'WRITE';
    const canResetTable = this.props.auditCalendarYear !== this.table.startYear;

    const usingSmallWorksite = this.table.isEligibleForSmallWorksiteCalculation();
    return <div id={this.props.id} className='is-relative'>
      {this.props.lock && <AssessmentLock lock={this.props.lock} editLock={editLock} />}
      {
        canResetTable &&
        <div className='mt-2'>
          <button title='Reset the injury rate table' className='button is-primary'
            onClick={() => window.confirm(`Your self evaluation is for ${this.props.auditCalendarYear}. Update the injury rate table to reflect this?`) && this.resetTable()}>
            Reset Table
          </button>
          <div className='has-text-info'>Update the injury rate table to reflect your current self evaluation year of {this.props.auditCalendarYear}</div>
        </div>
      }
      <table className='table injury-rate-table'>
        <thead>
          <tr>
            <th className='injury-rate-table-year'>Year</th>
            <th>Total Number Employees</th>
            <th>Total Work Hours</th>
            <th>Total Number of Injuries &amp; Illnesses</th>
            <th>Total Case Incidence Rate for Injuries and Illnesses (TCIR)	</th>
            <th>Total Number of Injury &amp; Illness Cases Involving Days Away from Work, Restricted Work Activity, and/or Job Transfer</th>
            <th>Days Away from Work, Restricted Work Activity, and/or Job Transfer Rate (DART rate)</th>
          </tr>
        </thead>
        <tbody>
          {data.rows.map(row => <tr key={row.year}>
            <td className='injury-rate-table-year'>{row.year}</td>
            <td><input readOnly={this.props.readOnly} type='number' className='input' value={row.employees || ''} onChange={evt => this.setRowProperty(row, 'employees', evt.currentTarget.value)} onFocus={this.props.onFocus} /></td>
            <td><input readOnly={this.props.readOnly} type='number' className='input' value={row.workHours || ''} onChange={evt => this.setRowProperty(row, 'workHours', evt.currentTarget.value)} onFocus={this.props.onFocus} /></td>
            <td><input readOnly={this.props.readOnly} type='number' className='input' value={row.injuries || ''} onChange={evt => this.setRowProperty(row, 'injuries', evt.currentTarget.value)} onFocus={this.props.onFocus} /></td>
            <td className='number-cell'>{this.formatNumber(row.tcir)}</td>
            <td><input
              readOnly={this.props.readOnly}
              className={`input ${row.dartIsValid ? '' : 'is-danger'}`}
              title={row.dartIsValid ? undefined : 'The number of incidents involving days away from work cannot exceed the total number of incidents'}
              type='number' value={row.daysAwayFromWork || ''}
              onChange={evt => this.setRowProperty(row, 'daysAwayFromWork', evt.currentTarget.value)} onFocus={this.props.onFocus}
            />
            </td>
            <td className='number-cell'>{this.formatNumber(row.dart)}</td>
          </tr>)}
          {
            data.rows.length > 1 && <tr className={usingSmallWorksite ? 'injury-rate-table-naics-info' : ''}>
              <td colSpan={2}>{data.describeDataUsed()}</td>
              <td className='number-cell'>{data.totalHours}</td>
              <td className='number-cell'>{data.totalInjuries}</td>
              <td className='number-cell'>{this.formatNumber(data.averageTCIR)}</td>
              <td className='number-cell'>{data.totalDaysAwayFromWork}</td>
              <td className='number-cell'>{this.formatNumber(data.averageDART)}</td>
            </tr>
          }
          {usingSmallWorksite && <tr>
            <td colSpan={7} className='has-text-danger'>Using the <i>alternative calculation for small worksites</i> based on total work hours in the last year and the most recent data for your NAICS code.</td>
          </tr>
          }
          <tr className='injury-rate-table-naics-info'>
            <td colSpan={4}>
              <div className='field my-2 is-flex is-align-items-center' id={this.props.id}>
                <span className='mr-2'>Choose NAICS Sector</span>
                <div className='select'>
                  <select disabled={this.props.readOnly} value={this.state.naicsSectorType} onChange={evt => this.changeSector(evt.currentTarget.value as NAICSSectorType)} >
                    <option key='naics-private' value='private'>{naicsSectorTypeLookup['private']}</option>
                    <option key='naics-state' value='state'>{naicsSectorTypeLookup['state']}</option>
                    <option key='naics-local' value='local'>{naicsSectorTypeLookup['local']}</option>
                  </select>
                </div>
              </div>
            </td>
          </tr>
          {Object
            .keys(data.historicalRates ?? {})
            .sort((a, b) => (+a) - (+b))
            .reduce((allYears: Array<string | null>, currentYear: string | null) =>
              // if we don't have historical rates we want to show an empty row with a rate input field
              // the null value will give us that row
              [...allYears, currentYear], Object.keys(data.historicalRates ?? {}).length === 0 ? [null] : []
            )
            .map((year: string | null, row: number) => {
              const showInput = row === 0;
              const showInjuryRates = data.blsTCIR > 0;
              const showRateOrigination = row === 1 && showInjuryRates;

              return <tr className='injury-rate-table-naics-info' key={year ?? 'no-year'}>
                {showInput && <td colSpan={showInjuryRates ? 3 : 7}>
                  <span>Most recent published BLS rates for NAICS code</span>
                  <input
                    readOnly={this.props.readOnly}
                    className='input inline-input'
                    list='naics-codes'
                    autoComplete='off'
                    value={data.naicsCode || ''}
                    onChange={evt => this.setNAICS(evt.currentTarget.value, this.state.naicsSectorType)}
                    onFocus={this.props.onFocus}
                  />
                </td>}

                {showRateOrigination && <td colSpan={showInjuryRates ? 3 : 7}>
                  <span className='help is-info'>
                    Injury rates taken from {this.state.rateState === 'USA' ? 'federal' : this.state.rateState + ' state'} BLS data.
                  </span>
                </td>}

                {showInjuryRates && <>
                  {!showInput && !showRateOrigination && <td colSpan={3} />}
                  <td className='number-cell'>{year}</td>
                  <td className='number-cell'>{data.historicalRates[year].tcirRate}</td>
                  <td className='number-cell' />
                  <td className='number-cell'>{data.historicalRates[year].dartRate}</td>
                </>}
              </tr>;
            })
          }
          {
            !validRates &&
            <tr>
              <td colSpan={3}></td>
              <td className='number-cell'>{haveMinimumYear ? this.table.startYear - 1 : this.table.startYear}</td>
              <td colSpan={3} className='has-text-danger'>Published rates from the Bureau of Labor Statistics are not yet available for {haveMinimumYear ? this.table.startYear - 1 : this.table.startYear}</td>
            </tr>
          }
          {this.state.naicsCodeSwapped && data.blsTCIR > 0 && <tr>
            <td colSpan={7}><p className='help is-danger injury-rate-table-naics-info'>
              We could not find U.S. Bureau of Labor Statistics (BLS) injury rate data for the NAICS Code {this.state.searchedCode}. <br />
              Instead we are using data for {data.naicsCode}.
            </p></td>
          </tr>
          }
          {data.blsTCIR <= 0 && <tr>
            <td colSpan={7}><p className='help is-danger injury-rate-table-naics-info'>
              We could not find U.S. Bureau of Labor Statistics (BLS) injury rate data for the NAICS Code {this.state.searchedCode} within {naicsSectorTypeLookup[this.state.naicsSectorType]}. <br />
              Please choose for a industry that BLS publishes data for.
            </p></td>
          </tr>
          }
          <tr className='injury-rate-table-national-average'>
            <td colSpan={3}>Percent above or below national average</td>
            {
              !validRates
                ? <>
                  <td className='has-text-danger' colSpan={2}>(Cannot be calculated at this time)</td>
                  <td className='has-text-danger' colSpan={3}>Please check back in Q4 {this.table.startYear} for new rates or contact support if you have other questions</td>
                </>
                : <>
                  <td className='number-cell'>{this.formatNumber(data.percentDiffTCIR)}%</td>
                  <td></td>
                  <td className='number-cell'>{this.formatNumber(data.percentDiffDART)}%</td>
                </>
            }
          </tr>
        </tbody>
      </table>
    </div>;
  }

  private setNAICS(value: string, sectorType: NAICSSectorType): void {
    const code = value.trim();
    const rateSearchResult = this.blsRepo.getInjuryRate({ naicsCode: code, sectorType });
    const foundNAICSCode = rateSearchResult.rate?.naics || '';

    this.setState({
      naicsCodeSwapped: foundNAICSCode !== code,
      rateState: rateSearchResult?.rate?.state,
      searchedCode: code,
    });

    const data = this.table;
    data.naicsCode = value;
    data.naicsSectorType = sectorType;
    data.blsTCIR = 0;
    data.blsDART = 0;

    if (code.length === 0) {
      this.recalculateAverages();
      return;
    }

    if (rateSearchResult.rate?.naics !== undefined) {
      data.naicsCode = rateSearchResult.rate.naics;
      data.blsTCIR = Number(rateSearchResult.rate.tcir);
      data.blsDART = Number(rateSearchResult.rate.dart);
      this.recalculateAverages();
      return;
    }
  }

  private setRowProperty(row: InjuryRateRow, propName: string, value: string): void {
    (row as any)[propName] = value;
    row.recalculate();
    this.recalculateAverages();
  }

  private recalculateAverages() {
    const options = new InjuryRateTableOptions();
    options.numberOfYears = 3;
    this.table.recalculateAverages(options);
    this.recalculateHistoricalRates();
    this.markChanged();
  }

  /**
   * recalculateHistoricalRates looks for the required historical years of
   * BLS data to store historical rates in the payload.
   * If any conditions are unmet then we store an empty payload.
   */
  private recalculateHistoricalRates() {
    if (this.table.naicsCode === undefined || this.table.naicsCode === null || this.table.naicsCode?.trim().length === 0) {
      return;
    }
    const requiredYearCount = 3;
    this.table.historicalRates = {};
    const availableYears = this.blsRepo.getAvailableInjuryRateYears();
    if (availableYears.length < requiredYearCount) {
      return;
    }

    availableYears.sort();
    const availableYearCount = availableYears.length;
    const computationYears = [];
    for (let i = 1; i <= requiredYearCount; i++) {
      computationYears.push(availableYears[availableYearCount-i]);
    }
    computationYears.sort();

    for (const yyyy of computationYears) {
      const annualRate = this.blsRepo.getInjuryRate({
        naicsCode: this.table.naicsCode,
        sectorType: this.table.naicsSectorType,
        year: yyyy
      });
      if (annualRate.rate === undefined || Number.isNaN(annualRate.rate.tcir)) {
        continue;
      }
      const nextRate: HistoricalNAICSRate = {
        naicsCode: annualRate.rate.naics,
        tcirRate: Number(annualRate.rate.tcir),
        dartRate: Number(annualRate.rate.dart)
      };
      this.table.historicalRates[yyyy] = nextRate;
    }
  }

  private resetTable() {
    const options = new InjuryRateTableOptions();
    options.numberOfYears = 3;
    const rowsToInclude = this.table.rows.filter(row => (this.props.auditCalendarYear - row.year) >= 0 && (this.props.auditCalendarYear - row.year) <= 3 );
    //Configure table with rows being included
    this.table.configure(this.props.auditCalendarYear, options, rowsToInclude);
    this.recalculateAverages();
  }

  private markChanged() {
    this.props.onChange(this.table);
    this.setState({});
  }

  private formatNumber(n: number): string {
    return (isFinite(n) && n !== null) ? n.toFixed(2) : '-';
  }

  /**
   * This function looks redundant, but it acts to simplify updating the state value
   * and then passing a call to setNAICS to search again.
   */
  private changeSector(value: NAICSSectorType): void {
    this.setState({ naicsSectorType: value });
    this.setNAICS(this.table.naicsCode, value);
  }
}

class ParticipantInjuryRateTableEditorProps {
  onChange?: (value: any) => void;
  value: InjuryRateTable;
  id?: string;
  auditId: number;
  readOnly?: boolean;
  onFocus?: () => Promise<boolean>;
  lock?: Lock;
  auditCalendarYear?: number;
  auditStartedOn?: number;
}

class ParticipantInjuryRateTableEditorState {
  naicsCodeSwapped: boolean;
  searchedCode: string;
  naicsSectorType: NAICSSectorType;
  rateState: string;
}
